import { useCoreWorkspaceContext } from '@Core/Provider/CoreWorkspaceProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSwitchWorkspaceDialog } from './hooks/useSwitchWorkspaceDialog'
import { convertUrlWithParams, getS3Url, isLocalhost, isStudioProduction } from '@Core/Helpers/Url'
import { Avatar, Badge, Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useCorePlanContext } from '@Core/Provider/CorePlanProvider'
import { isPlanPersonalFree, isPlanPersonalVip } from '@Core/Helpers/Plan'
import clsx from 'clsx'
import { truncate } from 'lodash'
import { TbExchange } from 'react-icons/tb'
import { ChangeCircle, ChangeCircleOutlined } from '@mui/icons-material'
import CurrentPlanLabel from '@Core/Components/Common/Plan/CurrentPlanLabel'
import urlcat from 'urlcat'
import { QUIZ_ROUTER_EXAM } from '@App/Quiz/Modules/Exam/configs/const'
import { getAuthTokenSession } from '@Core/Helpers/Session'
// import PropTypes from 'prop-types'

const CurrentWorkspace = ({ collapsed }) => {
	const { t } = useTranslation()
	// const { loadingSwitchWorkspace } = useCoreAuthActions()
	const { currentWorkspace, loadinCurrentWorkspace, myWorkspaces } = useCoreWorkspaceContext()
	// console.log('============= currentWorkspace', currentWorkspace)
	const { currentPlan } = useCorePlanContext()
	// const {mobile} = useBreakpoints()
	const { handleOpenSwitchWorkspaceDialog, renderSwitchWorkspaceDialog } = useSwitchWorkspaceDialog()

	const viewChannel = () => {
		let url = `kenh-de-thi/${currentWorkspace?.alias}`
		const token = getAuthTokenSession()
		if (isStudioProduction()) {
			url = `https://eduquiz.vn/${url}`
			url = convertUrlWithParams(url, token)
		} else {
			url = `https://eduquiz-landing-page-dev.vercel.app/${url}`
			url = convertUrlWithParams(url, token)
		}

		if (isLocalhost()) {
			url = urlcat(`/quiz/${QUIZ_ROUTER_EXAM.channel}`, { alias: currentWorkspace?.alias })
		}

		window.open(url, '_blank')
	}

	if (loadinCurrentWorkspace) {
		return (
			<div className="flex items-center gap-4 p-2 bg-white rounded-md shadow-sank">
				<div className="bg-gray-200 w-[46px] rounded-full aspect-square animate-pulse"> </div>
				<div className="w-[120px] rounded-md aspect-[3/1] bg-gray-200 animate-pulse"> </div>
			</div>
		)
	}

	if (currentWorkspace?.id) {
		return (
			<div
				// onClick={handleOpenSwitchWorkspaceDialog}
				className="flex items-center group  gap-3 duration-300 text-white font-[500] p-2 rounded-md  transition-all cursor-pointer "
			>
				<Tooltip title={t('Xem kênh đề thi')}>
					<Box
						className={clsx('flex gap-3', {
							'items-center': !currentPlan?.id
						})}
						onClick={viewChannel}
					>
						<Avatar
							src={getS3Url(
								currentWorkspace?.avatar ??
									'https://img.freepik.com/free-vector/designer-workplace-composition_98292-7218.jpg?t=st=1710922883~exp=1710926483~hmac=5ed0079f5226aafb420e7da7e11fcf4c5f8869c5d2f86695c3f0e8194e073c73&w=200'
							)}
							alt="educenter"
							// sizes={30}
							className="border border-solid border-gray-100 w-[40px] h-[40px]"
						/>
						<div
							className={clsx('flex flex-col ', {
								hidden: collapsed
							})}
						>
							<Typography variant="M14" className="text-mainBlue whitespace-nowrap">
								{truncate(currentWorkspace?.name, 20)}
							</Typography>
							{/* <CurrentPlanLabel currentPlan={currentPlan} /> */}
							<Box sx={{ lineHeight: '1.25rem' }}>
								<Typography variant="M12" color="textSecondary">
									Kênh đề thi
								</Typography>
							</Box>
						</div>
					</Box>
				</Tooltip>
				{myWorkspaces?.length > 1 && !collapsed && (
					<Tooltip title={t('Chuyển đổi workspace')}>
						<IconButton
							className="ml-auto transition-all duration-300 "
							color="primary"
							onClick={handleOpenSwitchWorkspaceDialog}
						>
							<ChangeCircleOutlined className="group-hover:animate-spin" />
						</IconButton>
					</Tooltip>
				)}
				{renderSwitchWorkspaceDialog()}
			</div>
		)
	}

	return null
}

// CurrentWorkspace.defaultProps = {}

// CurrentWorkspace.propTypes = {}

export default React.memo(CurrentWorkspace)
