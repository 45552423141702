import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { CoreInput } from '@Core/Components/Input'
import Yup from '@Core/Helpers/Yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, IconButton, InputAdornment, Typography } from '@mui/material'
import { useBoolean } from 'ahooks'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
// import PropTypes from 'prop-types'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useTranslation } from 'react-i18next'
import { ROUTER_AUTH, TRANSLATE_AUTH } from '@App/Auth/configs/const'
import SocialAuthen from '@App/Auth/components/SocialAuthen'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'

const LoginDialog = ({ open, handleClose }) => {
	const { t } = useTranslation(TRANSLATE_AUTH)
	const [showTextInputId, setShowTextInputId] = useState('')
	const navigate = useNavigate()

	const handleShowTextInput = id => {
		setShowTextInputId(prev => {
			if (prev === id) {
				return ''
			}
			return id
		})
	}

	const {
		control,
		formState: { isDirty, isSubmitting }
	} = useForm({
		mode: 'onTouched',
		defaultValues: {},
		resolver: yupResolver(
			Yup.object({
				username: Yup.string().required().trim().min(6).max(255),
				password: Yup.string().required().trim().min(6).max(255)
			})
		)
	})

	const onSubmit = () => {}

	return (
		<div>
			<CoreDialog
				open={open}
				handleClose={handleClose}
				dialogTitle="Đăng nhập"
				dialogContent={
					<div>
						<Typography className="mb-3">
							Dùng email của bạn hoặc tài khoản để đăng nhập Eduquiz!
						</Typography>
						<div>
							<form onSubmit={onSubmit} className="flex flex-col gap-formItem">
								<SocialAuthen />
								<CoreInput
									control={control}
									label={t('Tài khoản đăng nhập')}
									placeholder={t('Nhập tài khoản hoặc email')}
									name="username"
									size="small"
								/>
								<div>
									<CoreInput
										control={control}
										label={t('label.password')}
										placeholder={t('placeholder.password')}
										name="password"
										size="small"
										type={showTextInputId === 'password' ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => handleShowTextInput('password')}
														onMouseDown={e => e.preventDefault()}
														edge="end"
													>
														{showTextInputId === 'password' ? (
															<VisibilityOffIcon />
														) : (
															<VisibilityIcon className="text-mainViolet" />
														)}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
									<Box className="flex justify-end mt-1">
										<Button
											onClick={() => navigate(`${ROUTER_AUTH.forgot_password}`)}
											className="text-mainBlue text-[1rem]"
										>
											{t('Quên mật khẩu')}
										</Button>
									</Box>
								</div>
								<LoadingButton
									type="submit"
									variant="main-gradient"
									loading={isSubmitting}
									disabled={!isDirty || isSubmitting}
								>
									{t('btn.login')}
								</LoadingButton>
							</form>
						</div>
					</div>
				}
			/>
		</div>
	)
}

// LoginDialog.defaultProps = {}

// LoginDialog.propTypes = {}

export default React.memo(LoginDialog)
