import onboardingSerivce from '@App/Auth/services/onboardingService'
import { useRequest } from 'ahooks'
import React, { useEffect, useState } from 'react'
import { useCoreAuth } from '../CoreAuthProvider'
import {
	SESSION_STORAGE,
	getDataSession,
	setCurrentWorkspaceSession,
	setDataSession,
	setSystemSettingSession
} from '@Core/Helpers/Session'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTER_AUTH } from '@App/Auth/configs/const'
import systemService from '@App/Studio/services/systemService'
import PageLoader from '@Core/Components/Common/PageLoader'
import workspaceService from '@App/Studio/services/workspaceService'
import { successMsg } from '@Core/Helpers/Message'
import { useTranslation } from 'react-i18next'
import { ROUTER_WORKSPACE_SYSTEM } from '@App/Studio/Modules/Workspace/System/config/const'
import CorePlanProvider from '../CorePlanProvider'
import { hideLoadingPage, showLoadingPage } from '@Core/Helpers/System'
import CoreOnboardingProvider from '../CoreOnboardingProvider'
import WalletFeatureProvider from '../CoreWalletFeature/WalletFeatureProvider'
import { ROUTER_WORKSPACE_EXAM } from '@App/Studio/Modules/Workspace/Exam/configs/const'
// import PropTypes from 'prop-types'

const CoreWorkspaceContext = React.createContext({
	currentWorkspace: null,
	onboardingCompleted: false,
	myWorkspaces: [],
	switchWorkspace: () => {}
})

export const useCoreWorkspaceContext = () => React.useContext(CoreWorkspaceContext)

const checkOnboardingSession = getDataSession(SESSION_STORAGE, 'checkOnboarding') ?? false

const CoreWorkspaceProvider = ({ children, ...resProps }) => {
	const { t } = useTranslation()
	const { user, isAuthenticated } = useCoreAuth()
	const navigate = useNavigate()

	const [loadingWorkspace, setLoadingWorkspace] = useState(true)
	// const [currentWorkspace, setCurrenWorkspace] = useState(null)
	const [onboardingCompleted, setOnboardingCompleted] = useState(true)

	const {
		data: currentWorkspace,
		runAsync: getCurrentWorkspace,
		loading: loadinCurrentWorkspace
	} = useRequest(workspaceService.getCurrentWorkspace, {
		manual: true,
		onSuccess: res => {
			if (res?.id) {
				setCurrentWorkspaceSession(res)
			} else {
				navigate(ROUTER_WORKSPACE_EXAM.root)
			}

			setLoadingWorkspace(false)
			// if (window.location.pathname === '/' || window.location.pathname.includes('/user')) {
			// 	navigate(ROUTER_WORKSPACE_EXAM.list)
			// }
		},
		onError: e => {
			// navigate(ROUTER_AUTH.workspaces)
		}
	})

	const { runAsync: updateCurrentWorkspace } = useRequest(workspaceService.updateWorkspace, {
		manual: true,
		onSuccess: res => {
			getCurrentWorkspace()
			successMsg(t('Lưu thông tin thành công'))
		}
	})

	const {
		data: myWorkspaces,
		loading: fetchingWorkspaces,
		run: getMyWorkspaces
	} = useRequest(workspaceService.getMyWorkspaces, {
		ready: isAuthenticated,
		// manual: true,
		cacheKey: `myWorkspaces-${user?.id}`,
		cacheTime: 30 * 60 * 1000,
		staleTime: 30 * 60 * 1000
	})

	const { runAsync: switchWorkspace, loading: loadingSwitchWorkspace } = useRequest(
		workspaceService.switchWorkspace,
		{
			manual: true,
			onBefore: () => {
				showLoadingPage(true)
			},
			onSuccess: async () => {
				// await getCurrentWorkspace()
				successMsg('Chuyển đổi workspace thành công')
				window.location.href = '/'
			},
			onFinally: () => {
				hideLoadingPage()
			}
		}
	)

	// const switchWorkspace = async workspace_id => {
	// 	setLoadingWorkspace(true)
	// 	try {
	// 		await workspaceService.switchWorkspace({ workspace_id })
	// 		setLoadingWorkspace(false)
	// 		getCurrentWorkspace()
	// 		return true
	// 	} catch (e) {
	// 		// something went wrong bro :D
	// 		setLoadingWorkspace(false)
	// 		return false
	// 	}
	// }

	const {
		data: onboardingSteps,
		run: checkOnboarding,
		loading = true
	} = useRequest(onboardingSerivce.checkOnboarding, {
		manual: true,
		onSuccess: res => {
			// console.log('============= res?.steps.every(step => step.complete)', res?.steps)
			if (res?.steps.every(step => step.complete)) {
				setLoadingWorkspace(true)
				getCurrentWorkspace()
				setOnboardingCompleted(true)
				setDataSession(SESSION_STORAGE, 'checkOnboarding', true)
			} else {
				navigate(ROUTER_WORKSPACE_SYSTEM.onboarding)
				setLoadingWorkspace(false)
			}
		}
	})

	useEffect(() => {
		if (isAuthenticated) {
			// systemService.getGeneralConfig().then((data, error) => {
			// 	if (data) {
			// 		setSystemSettingSession(data)
			// 	}
			// })
			setOnboardingCompleted(true)
			setLoadingWorkspace(true)
			getCurrentWorkspace()
			// if (checkOnboardingSession) {
			// 	setOnboardingCompleted(true)
			// 	setLoadingWorkspace(true)
			// 	getCurrentWorkspace()
			// } else {
			// 	checkOnboarding()
			// }
		}

		// const onboardingStatus = await onboardingSerivce.checkOnboarding()
	}, [isAuthenticated])

	const pageContext = {
		getCurrentWorkspace,
		onboardingSteps,
		currentWorkspace,
		updateCurrentWorkspace,
		onboardingCompleted,
		loadinCurrentWorkspace,
		myWorkspaces,
		switchWorkspace,
		fetchingWorkspaces,
		...resProps
	}

	if (!isAuthenticated) return children

	if (loading || loadingWorkspace) return <PageLoader />

	// if (!onboardingCompleted || !currentWorkspace) {
	// 	return children
	// }
	return (
		<CoreWorkspaceContext.Provider value={pageContext}>
			<WalletFeatureProvider>
				{/* <CorePlanProvider> */}
				<CoreOnboardingProvider>{children}</CoreOnboardingProvider>
				{/* </CorePlanProvider> */}
			</WalletFeatureProvider>
		</CoreWorkspaceContext.Provider>
	)
}

// CoreWorkspaceProvider.defaultProps = {}

// CoreWorkspaceProvider.propTypes = {}

export default React.memo(CoreWorkspaceProvider)
