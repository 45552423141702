import React from 'react'
import { ROUTER_ORDER } from './const'

const CartLazy = React.lazy(() => import('../pages/Cart'))
const CheckoutLazy = React.lazy(() => import('../pages/Checkout'))
const PlanLazy = React.lazy(() => import('../pages/Plan'))
const LazyProducts = React.lazy(() => import('../pages/Products'))
const LazyPricing = React.lazy(() => import('../pages/Pricing'))

export const orderRouterConfig = {
	auth: [],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
					// collapsed: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_ORDER.pricing,
			element: <LazyPricing />,
			exact: true
		},
		{
			path: ROUTER_ORDER.product_feature,
			element: <LazyProducts />,
			exact: true
		},
		{
			path: ROUTER_ORDER.plan,
			element: <PlanLazy />,
			exact: true
		},
		{
			path: ROUTER_ORDER.cart,
			element: <CartLazy />,
			exact: true,
			handle: {
				notCheckSubscription: true
			}
		},
		{
			path: `${ROUTER_ORDER.checkout}/:order_id`,
			element: <CheckoutLazy />,
			exact: true,
			handle: {
				notCheckSubscription: true
			}
		}
	]
}
