import { useRequest, useUpdateEffect } from 'ahooks'
import React, { useEffect, useState } from 'react'
import walletFeatureService from './walletFeatureService'
import { useCoreAuth } from '@Core/Provider/CoreAuthProvider'
import CorePlanFeatureProvider from '../CorePlanProvider/CorePlanFeatureProvider'
import { warningMsg } from '@Core/Helpers/Message'
import AlertUpgradeFeature from './components/AlertUpgradeFeature'
import { WALLET_FEATURE } from '@Core/Components/const'
import { useCoreWorkspaceContext } from '../CoreWorkspaceProvider'
import PageLoader from '@Core/Components/Common/PageLoader'
import Page500 from '@App/CommonPage/ErrorPage/pages/Page500'
import { useCoreAppContext } from '../CoreAppProvider'
// import PropTypes from 'prop-types'

const featureObject = { loading: false, balance: 0, canUseFeature: () => {}, fetchBalance: () => {} }
const WalletFeatureContext = React.createContext({
	// myWallets: {},
	// loadingMyWallets: true
	featureCreateExam: featureObject,
	featureCreateQuestion: featureObject,
	featureStudyExam: featureObject,
	featureTestExam: featureObject,
	renderAlertFeatureExamQuestion: () => {}
})

export const useWalletFeatureContext = () => React.useContext(WalletFeatureContext)

const useFeatureBalance = ({ slug, manual }) => {
	const { isAuthenticated } = useCoreAuth()
	const {
		data,
		runAsync: fetchBalance,
		loading
	} = useRequest(() => walletFeatureService.getBalance(slug), {
		// defaultParams: [feature],
		ready: isAuthenticated,
		cacheKey: `handleFetchFeatureBalance-${slug}`,
		cacheTime: 10000,
		staleTime: 10000,
		manual
	})

	const canUseFeature = () => {
		return data?.balance > 0
	}

	return { loading, balance: data?.balance ?? 0, canUseFeature, fetchBalance }
}

export const alertFeatureNotUsed = () => {
	warningMsg('Bạn đã hết lượt sử dụng tính năng này, vui lòng kiểm tra lại!')
}

const WalletFeatureProvider = props => {
	const { isAuthenticated } = useCoreAuth()
	const { currentWorkspace } = useCoreWorkspaceContext()

	const [loading, setLoading] = useState(true)
	const { setError } = useCoreAppContext()

	const { data, run: initWalletFeature } = useRequest(walletFeatureService.init, {
		manual: true,
		onSuccess: res => {
			setLoading(false)
		},
		onError: () => {
			setError(500)
		}
	})

	const featureCreateExam = useFeatureBalance({ slug: 'create_exam', manual: true })
	const featureCreateQuestion = useFeatureBalance({ slug: 'create_question', manual: true })
	const featureStudyExam = useFeatureBalance({ slug: 'study_exam', manual: true })
	const featureTestExam = useFeatureBalance({ slug: 'test_exam', manual: true })

	// const { data: myWallets, loading: loadingMyWallets } = useRequest(walletFeatureService.getWallets, {
	// 	ready: isAuthenticated
	// })

	// console.log('============= currentWorkspace', currentWorkspace)

	const loadingFeatureExamQuestion = featureCreateExam.loading || featureCreateQuestion.loading

	const canUseFeatureExamQuestion = () => {
		return featureCreateExam.canUseFeature() && featureCreateQuestion.canUseFeature()
	}

	const renderAlertFeatureExamQuestion = () => {
		if (!featureCreateExam.canUseFeature() && !featureCreateExam.loading) {
			return <AlertUpgradeFeature className="mb-2" slug={WALLET_FEATURE.create_exam.slug} showButton />
		}
		if (!featureCreateQuestion.canUseFeature() && !featureCreateExam.loading) {
			return <AlertUpgradeFeature className="mb-2" slug={WALLET_FEATURE.create_question.slug} showButton />
		}
	}

	useEffect(() => {
		if (currentWorkspace?.id && !currentWorkspace?.is_sync_wallet) {
			initWalletFeature(currentWorkspace?.id)
		} else {
			setLoading(false)
		}
	}, [currentWorkspace])

	useUpdateEffect(() => {
		if (!loading && currentWorkspace?.id) {
			featureCreateExam.fetchBalance()
			featureCreateQuestion.fetchBalance()
		}
	}, [loading, currentWorkspace])

	const context = {
		// myWallets,
		// loadingMyWallets
		featureCreateExam,
		featureCreateQuestion,
		featureStudyExam,
		featureTestExam,
		renderAlertFeatureExamQuestion,
		loadingFeatureExamQuestion,
		canUseFeatureExamQuestion
	}

	if (loading) {
		return <PageLoader />
	}

	return (
		<WalletFeatureContext.Provider value={context}>
			<CorePlanFeatureProvider>{props.children}</CorePlanFeatureProvider>
		</WalletFeatureContext.Provider>
	)
}

// WalletFeatureProvider.defaultProps = {}

// WalletFeatureProvider.propTypes = {}

export default React.memo(WalletFeatureProvider)
