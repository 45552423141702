import BaseService from '@Core/axios/BaseService'
import fileDownload from 'js-file-download'

class ExamRoom extends BaseService {
	BASE_ENDPOINT = '/examroom/studio/api/v1/admin/exam-rooms'

	saveSetting = data => {
		const endpoint = '/examroom/studio/api/v1/admin/exam-rooms/save-setting'
		return this.request.post(endpoint, data)
	}

	// tạo mới Bài kiểm tra
	createExamHomework = data => {
		const endpoint = '/examroom/studio/api/v1/admin/exam-rooms/create-exercise'

		return this.request.post(endpoint, data)
	}

	// check data bài tập
	getExamExercise = examId => {
		const endpoint = `examroom/studio/api/v1/admin/exam-rooms/check-exam-exercise/${examId}`

		return this.request.get(endpoint)
	}

	statisByStatus = () => {
		const endpoint = 'examroom/studio/api/v1/admin/exam-rooms/statistical-by-status'
		return this.request.get(endpoint)
	}

	changeStatus = data => {
		const endpoint = '/examroom/studio/api/v1/admin/exam-rooms/change-status'

		return this.request.post(endpoint, data)
	}

	export = params => {
		const endpoint = '/quizexam/studio/api/v1/admin/exam-results/export'

		return this.request.get(endpoint, {
			params
		})
	}
}

const adminExamRoomService = new ExamRoom()

export default adminExamRoomService
