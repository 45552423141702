const basePath = 'order'
export const ROUTER_ORDER = {
	checkout: `${basePath}/checkout`,
	cart: `${basePath}/cart`,
	plan: `${basePath}/plan`,
	product_feature: `${basePath}/product-feature`,
	pricing: `${basePath}/pricing`
}

export const TRANSLATE_ORDER = {
	checkout: `${basePath}/checkout`,
	cart: `${basePath}/cart`
}
