import { ROUTER_WORKSPACE_PLAN } from '@App/Studio/Modules/Workspace/Plan/configs/const'
import { PLAN_FEATURE, WALLET_FEATURE } from '@Core/Components/const'
import { Alert, Button } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'

const AlertUpgradeFeature = ({ slug, customMsg, className = '', showButton, onClick = () => {}, variant }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	// if (!msg) return null
	const msg = useMemo(() => {
		if (customMsg) {
			return customMsg
		}
		if (slug === WALLET_FEATURE.create_exam.slug) {
			return t('Hết lượt tạo đề thi, vui lòng kiểm tra ví sử dụng')
		}
		if (slug === WALLET_FEATURE.create_exam.slug) {
			return t('Hết lượt tạo câu hỏi, vui lòng kiểm tra ví sử dụng')
		}
	}, [t, slug, customMsg])

	if (!msg) return null
	return (
		<Alert
			severity="warning"
			// variant="filled"
			variant={variant}
			className={className}
			action={
				showButton && (
					<Button
						size="small"
						className="whitespace-nowrap"
						onClick={() => {
							onClick()
							navigate(ROUTER_WORKSPACE_PLAN.root)
						}}
					>
						{t('Xem ví sử dụng')}
					</Button>
				)
			}
		>
			{msg}
		</Alert>
	)
}

// AlertUpgradePlan.defaultProps = {}

// AlertUpgradePlan.propTypes = {}

export default React.memo(AlertUpgradeFeature)
