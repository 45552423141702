import onboardingSerivce from '@App/Auth/services/onboardingService'
import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { useBoolean, useRequest } from 'ahooks'
import React, { useEffect, useState } from 'react'
import StepOne from './StepOne'
import { useCoreAuth } from '../CoreAuthProvider'
import AboutUs from './AboutUs'
import CreateWorkspace from './CreateWorkspace'
import { useCoreWorkspaceContext } from '../CoreWorkspaceProvider'
// import PropTypes from 'prop-types'

const CoreOnboardingContext = React.createContext({
	onboardingCompleted: false,
	handleCloseOnboarding: () => {},
	setStep: step => {}
})

export const useCoreOnboardingContext = () => React.useContext(CoreOnboardingContext)

const CoreOnboardingProvider = ({ skipWorkspace = false, ...props }) => {
	const { user, isAuthenticated } = useCoreAuth()
	const { currentWorkspace } = useCoreWorkspaceContext()
	const [open, { setFalse, setTrue }] = useBoolean(false)
	const [step, setStep] = useState('info')
	// console.log('============= step', step)
	useEffect(() => {
		if (isAuthenticated) {
			if (!user?.info?.fullname) {
				setStep('info')
				setTrue()
			} else if (!user?.info?.custom_data?.hear_about_us) {
				setStep('about-us')
				setTrue()
			} else if (!user?.info?.custom_data?.use_app_for) {
				setStep('about-us')
				setTrue()
			} else if (!currentWorkspace?.id && !skipWorkspace) {
				setStep('create-workpsace')
				setTrue()
			}
			// setStep('info')
			// setTrue()
		}
	}, [JSON.stringify(user), JSON.stringify(currentWorkspace)])

	const renderDiglogOnboarding = () => {
		return (
			<CoreDialog
				open={open}
				// handleClose={setFalse}
				dialogContent={
					<div>
						{step === 'info' && <StepOne handleNext={() => setStep('about-us')} />}
						{step === 'about-us' && <AboutUs handleBack={() => setStep('info')} />}
						{step === 'create-workpsace' && <CreateWorkspace />}
					</div>
				}
				maxWidth="md"
			/>
		)
	}

	const context = {
		handleCloseOnboarding: setFalse,
		setStep
	}

	return (
		<CoreOnboardingContext.Provider value={context}>
			{props.children}
			{renderDiglogOnboarding()}
		</CoreOnboardingContext.Provider>
	)
}

// CoreOnboardingProvider.defaultProps = {}

// CoreOnboardingProvider.propTypes = {}

export default React.memo(CoreOnboardingProvider)
