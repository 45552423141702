import { errorMsg } from '@Core/Helpers/Message'
import { useCorePlanContext } from '@Core/Provider/CorePlanProvider'
import { useCoreWorkspaceContext } from '@Core/Provider/CoreWorkspaceProvider'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from 'react-pro-sidebar'
import { Link, useLocation } from 'react-router-dom'
// import PropTypes from 'prop-types'

const MenuTypeItem = ({ item }) => {
	const { t } = useTranslation()
	const location = useLocation()
	// const { user } = useCoreAuth()
	const { currentWorkspace } = useCoreWorkspaceContext()
	const { currentPlan } = useCorePlanContext()

	const isDisabledMenu = useMemo(() => {
		if (currentWorkspace?.id) {
			if (currentPlan?.id) {
				return false
			}
			return true
		}
		return false
	}, [currentPlan, currentWorkspace])

	return (
		<MenuItem
			active={location.pathname.indexOf(item?.link) > -1}
			key={item.id}
			// component={isDisabledMenu && item?.notCheckSubscription !== true ? undefined : <Link to={item?.link} />}
			component={<Link to={item?.link} />}
			icon={item?.icon}
			// disabled={isDisabledMenu}
			// onClick={() => {
			// 	if (isDisabledMenu && item?.notCheckSubscription !== true) {
			// 		errorMsg(t('Vui lòng lựa chọn gói dịch vụ'))
			// 	}
			// }}
			suffix={item?.suffix}
		>
			{t(item?.title)}
		</MenuItem>
	)
}

// MenuItem.defaultProps = {}

// MenuItem.propTypes = {}

export default React.memo(MenuTypeItem)
