import { blue, deepOrange, deepPurple, indigo, pink, purple } from '@mui/material/colors'
import { borderColor, fontSize, lineHeight, minWidth } from '@mui/system'

export const studioColor = {
	primaryColor: '#3E65FE',
	main_violet: '#D23CFF',
	main_blue: '#3E65FE',
	mainGradient: 'linear-gradient(90.57deg, #3E65FE 0%, #D23CFF 100%)',
	mainGradient_hover: 'linear-gradient(90deg, rgba(27,73,254,1) 15%, rgba(199,11,255,1) 100%)',
	mainGradient_disabled: 'linear-gradient(90deg, #ABC3FF 15%, #F6D6FE 100%)',
	mainPink: '#ff7fce',
	mainOrange: '#fc956e',
	secondaryGradient: 'linear-gradient(90deg, rgba(255,127,206,1) 0%, rgba(252,149,110,1) 100%)',
	secondaryGradient_hover: 'linear-gradient(90deg, rgba(255,57,179,1) 0%, rgba(252,101,44,1) 100%)',
	secondaryGradient_disabled: 'linear-gradient(90deg, rgba(215,118,178,1) 0%, rgba(230,167,143,1) 100%)'
}

export const studioThemeCustomize = mode => ({
	palette: {
		mode,
		...(mode === 'light'
			? {
					primary: {
						main: studioColor.primaryColor
					},
					secondary: {
						main: pink[500]
					},
					violet: {
						main: studioColor.main_violet
					},
					blue: {
						main: studioColor.main_blue
					},
					background: {
						main: '#FFF',
						secondary: '#f2f3f5'
					}
			  }
			: {
					primary: {
						main: studioColor.primaryColor
					},
					secondary: {
						main: pink[500]
					},
					// violet: {
					// 	main: 'orange'
					// },
					// blue: {
					// 	main: 'red'
					// },
					background: {
						main: '#212121',
						secondary: '#272727'
					}
			  })
	},
	typography: {
		fontFamily: ['Inter', 'sans-serif'].join(',')
	},
	components: {
		MuiTypography: {
			variants: [
				{
					props: { color: 'gradient' },
					style: {
						// backgroundImage: linear - gradient(90.57deg, #3e65fe, #d23cff)!important;
						backgroundImage: `linear-gradient(90.57deg, #3e65fe, #d23cff) !important;`,
						color: 'transparent !important',
						backgroundClip: 'text !important'
					}
				},
				{
					props: { variant: 'M10' },
					style: {
						fontSize: '0.625rem',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1rem',
						letterSpacing: '0.25px'
					}
				},
				{
					props: { variant: 'SB10' },
					style: {
						fontSize: '0.625rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '1rem',
						letterSpacing: '0.25px'
					}
				},
				{
					props: { variant: 'M12' },
					style: {
						fontSize: '0.75rem',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1.25rem',
						letterSpacing: '0px'
					}
				},
				{
					props: { variant: 'SB12' },
					style: {
						fontSize: '0.75rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '1.25rem',
						letterSpacing: '0.25px'
					}
				},
				{
					props: { variant: 'L14' },
					style: {
						fontSize: '0.875rem',
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: '1.25rem'
					}
				},
				{
					props: { variant: 'M14' },
					style: {
						fontSize: '0.875rem',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1.25rem'
					}
				},
				{
					props: { variant: 'SB14' },
					style: {
						fontSize: '0.875rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '1.25rem'
					}
				},
				{
					props: { variant: 'M16' },
					style: {
						fontSize: '1rem',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1.5rem'
					}
				},
				{
					props: { variant: 'SB16' },
					style: {
						fontSize: '1rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '1.5rem'
					}
				},
				{
					props: { variant: 'M18' },
					style: {
						fontSize: '1.125rem',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1.625rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'SB18' },
					style: {
						fontSize: '1.125rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '1.625rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'M20' },
					style: {
						fontSize: '1.25rem',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1.625rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'SB20' },
					style: {
						fontSize: '1.25rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '1.625rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'M24' },
					style: {
						fontSize: '1.5rem',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '2rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'SB24' },
					style: {
						fontSize: '1.5rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '2rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'M28' },
					style: {
						fontSize: '2rem',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '2.125rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'SB28' },
					style: {
						fontSize: '2rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '2.125rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'M36' },
					style: {
						fontSize: '2.25rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: 'auto',
						letterSpacing: '-2px'
					}
				},
				{
					props: { variant: 'SB48' },
					style: {
						fontSize: '3rem',
						fontStyle: 'normal',
						fontWeight: 600,
						lineHeight: '2.125rem',
						letterSpacing: '-0.25px'
					}
				},
				{
					props: { variant: 'helper' },
					style: {
						fontSize: '0.875rem',
						fontStyle: 'normal',
						fontWeight: 500,
						color: '#71727D'
					}
				}
			]
		},

		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					fontSize: '0.875rem',
					borderRadius: 4
					// padding: '0.75rem 1rem'
				}
			},
			variants: [
				{
					props: { color: 'primary', gradient: 'primary', variant: 'contained' },
					style: {
						// fontStyle: 'normal',
						// fontWeight: 500,
						// lineHeight: '1rem',
						// // borderRadius: 8,
						// padding: '0.75rem 1rem',
						// color: 'white',

						backgroundImage: `${studioColor.mainGradient} !important`,
						// boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
						// transition: 'background 0.3s ease, box-shadow 0.3s ease !important',
						'&:hover': {
							background: studioColor.mainGradient_hover,
							color: 'white',
							boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'
						},
						'&:disabled': {
							backgroundImage: `${studioColor.mainGradient_disabled} !important`,
							boxShadow: 'none',
							color: 'white'
							// opacity: 0.8
						}
					}
				},
				{
					props: { variant: 'main-gradient' },
					style: {
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1rem',
						// borderRadius: 8,
						padding: '0.75rem 1rem',
						color: 'white',

						backgroundImage: `${studioColor.mainGradient} !important`,
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
						// transition: 'background 0.3s ease, box-shadow 0.3s ease !important',
						'&:hover': {
							background: studioColor.mainGradient_hover,
							color: 'white',
							boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'
						},
						'&:disabled': {
							backgroundImage: `${studioColor.mainGradient_disabled} !important`,
							boxShadow: 'none',
							color: 'white'
							// opacity: 0.8
						}
					}
				},
				{
					props: { variant: 'main-gradient', size: 'large' },
					style: {
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1rem',
						// borderRadius: 8,
						padding: '1.2rem 1.5rem',
						color: 'white',
						fontSize: '1rem',

						backgroundImage: `${studioColor.mainGradient} !important`,
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
						// transition: 'background 0.3s ease, box-shadow 0.3s ease !important',
						'&:hover': {
							background: studioColor.mainGradient_hover,
							color: 'white',
							boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'
						},
						'&:disabled': {
							boxShadow: 'none',
							// color: studioColor.mainGradient_disabled,
							opacity: 0.8,
							color: 'white'
						}
					}
				},

				{
					props: { variant: 'secondary-gradient' },

					style: {
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '1rem',
						// borderRadius: 8,
						// padding: '0.75rem 1rem',
						color: 'white',
						backgroundImage: `${studioColor.secondaryGradient} !important`,
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
						// transition: 'background 0.3s ease, box-shadow 0.3s ease !important',
						'&:hover': {
							background: studioColor.secondaryGradient_hover,
							color: 'white',
							boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'
						},
						'&:disabled': {
							boxShadow: 'none',
							color: studioColor.secondaryGradient_disabled
						}
					}
				},
				{
					props: { variant: 'secondary-gradient', size: 'large' },
					style: {
						fontStyle: 'normal',
						fontWeight: 500,

						borderRadius: 8,
						padding: '1rem 1.5rem',
						color: 'white',

						backgroundImage: `${studioColor.secondaryGradient} !important`,
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
						// transition: 'background 0.3s ease, box-shadow 0.3s ease !important',
						'&:hover': {
							background: studioColor.secondaryGradient_hover,
							color: 'white',
							boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'
						},
						'&:disabled': {
							boxShadow: 'none',
							color: studioColor.secondaryGradient_disabled
						}
					}
				},
				{
					props: { variant: 'primary' },
					style: {
						fontStyle: 'normal',
						fontWeight: 500,

						borderRadius: 8,
						padding: '0.75rem 1rem',
						color: studioColor.primaryColor,

						background: '#EDF2FF',
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
						// transition: 'background 0.3s ease, box-shadow 0.3s ease !important',
						'&:hover': {
							background: studioColor.primaryColor,
							color: 'white',
							boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'
						},
						'&:disabled': {
							boxShadow: 'none',
							color: 'white'
						}
					}
				},
				{
					props: { variant: 'primary', selected: true },
					style: {
						fontStyle: 'normal',
						fontWeight: 500,

						borderRadius: 8,
						padding: '0.75rem 1rem',
						color: '#EDF2FF',
						background: studioColor.primaryColor,
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
					}
				},

				{
					props: { catalog: true },
					style: {
						borderColor: '#D3D7EA',
						// fontSize: '1rem',
						// lineHeight: '24px',
						minWidth: '40px',
						lineHeight: '1rem'
						// color: '#000',
						// '&:hover': {
						// 	color: studioColor.primaryColor
						// }
					}
				},
				{
					props: { catalog: true, selected: true },
					style: {
						// borderColor: '#D3D7EA',
						// fontSize: '1rem',
						// lineHeight: '24px',
						// minWidth: '40px',
						// color: '#000',
						lineHeight: '1rem',
						color: '#EDF2FF',
						background: studioColor.primaryColor,
						'&:hover': {
							background: studioColor.primaryColor,
							color: '#EDF2FF'
						}
					}
				}
			]
		},
		MuiChip: {
			variants: [
				{
					props: { variant: 'mainBlue' },
					style: {
						backgroundColor: studioColor.main_blue,
						transition: 'all 0.3s',
						color: 'white',
						'&:hover': {
							backgroundColor: 'rgba(27,73,254,1)',
							color: 'white',

							boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'
						},
						'&:disabled': {
							boxShadow: 'none',
							color: studioColor.mainGradient_disabled
						}
					}
				}
			]
		},
		MuiCheckbox: {
			// !TODO hãy customize variant
			// defaultProps: {
			// 	icon: <FaRegCircle className="text-[1.4rem] text-[#A5A7BA]" />,
			// 	checkedIcon: <FaCircleCheck className="text-[1.4rem] text-[#15C740]" />
			// }
		}
		// MuiRadio: {
		// 	colors: [
		// 		{
		// 			props: { color: 'main-violet' },
		// 			style: {
		// 				color: studioColor.main_violet
		// 			}
		// 		}
		// 	]
		// }
	}
})
