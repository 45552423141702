import AppProvider from '@Core/Provider'
import { RouterProvider } from 'react-router-dom'
import React, { useEffect } from 'react'
import { MathJaxContext } from 'better-react-mathjax'

import { appRouter } from './config'
import Page403 from './CommonPage/ErrorPage/pages/Page403'
import PageMaintain from './CommonPage/ErrorPage/pages/PageMaintain'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const maintain = false

const App = props => {
	useEffect(() => {
		window.addEventListener('vite:preloadError', event => {
			window.location.reload() // for example, refresh the page
		})
	}, [])

	if (maintain) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen">
				<PageMaintain />
			</div>
		)
	}

	return (
		<AppProvider>
			<MathJaxContext>
				<RouterProvider router={appRouter} />
			</MathJaxContext>
		</AppProvider>
	)
}

export default App
