import BaseService from '@Core/axios/BaseService'
import { CRM_BE_URL } from 'env'

class Marketing extends BaseService {
	BASE_URL = CRM_BE_URL

	constructor(params) {
		super(params)
		this.setRequest()
	}

	getBannerPositions = () => {
		const endpoint = '/api/v1/marketing/banner-positions?system=studio'
		return this.withCache(() => this.request.get(endpoint), endpoint)
	}

	getFaqs = params => {
		const endpoint = '/api/v1/marketing/faqs'
		return this.withCache(() => this.request.get(endpoint, { params }), endpoint)
	}

	getVouchers = params => {
		const endpoint = '/api/v1/promotions/vouchers'
		return this.request.get(endpoint, { params })
	}

	getFlashSale = () => {
		const endpoint = '/api/v1/product-features/flashsale'
		return this.request.get(endpoint)
	}
}
const mktService = new Marketing()

export default mktService
