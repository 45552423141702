import { useCreateExamOptionContext } from '@App/Studio/Modules/Workspace/Exam/components/CreateExamOptionProvider'
import { useBreakpoints } from '@Core/hooks'
import { useLayoutContext } from '@App/Studio/Layout'
import { BugReport, Menu } from '@mui/icons-material'
import { Button, IconButton, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import UserHeader from './UserHeader'
import { MdLibraryAdd } from 'react-icons/md'
import { useCorePlanContext } from '@Core/Provider/CorePlanProvider'
import CurrentWorkspace from './CurrentWorkspace'
import SearchExamHeader from './SearchExamHeader'
import { FcOnlineSupport } from 'react-icons/fc'
import { FaBug } from 'react-icons/fa'
import { useCoreContactContext } from '@Core/Provider/CoreContactProvider'

const AppHeader = () => {
	const { routeSettings, layoutConfig, toggleOpenMobileSidebar } = useLayoutContext()

	const { mobile, laptop } = useBreakpoints()
	const { t } = useTranslation()
	const { handleOpenCreateOption } = useCreateExamOptionContext()
	const { currentPlan } = useCorePlanContext()
	const { handleOpenContact } = useCoreContactContext()

	if (!layoutConfig?.header?.display) return null
	return (
		<>
			<header className={clsx('w-full p-2 md:px-6  sticky top-0  z-[99] h-[66px]  flex items-center border-b')}>
				{!laptop && (
					<IconButton onClick={toggleOpenMobileSidebar} color="primary">
						<Menu />
					</IconButton>
				)}
				{!mobile && <CurrentWorkspace />}
				<div className="ml-5">
					<SearchExamHeader />
				</div>
				<div className="flex ml-auto">
					<div className="flex items-center gap-2 ml-auto">
						<Tooltip title={t('Báo lỗi tính năng')}>
							<Button
								onClick={handleOpenContact}
								// variant="contained"
								// gradient="primary"
								color="error"
								startIcon={<FaBug />}
								// disabled={!currentPlan?.id}
								// disableElevation
								// size="small"
							>
								{t('Báo lỗi')}
							</Button>
						</Tooltip>
						<Button
							onClick={handleOpenCreateOption}
							variant="contained"
							gradient="primary"
							startIcon={<MdLibraryAdd />}
							// disabled={!currentPlan?.id}
							// disableElevation
							// size="small"
						>
							{t('create_exam')}
						</Button>

						{/* <IconButton>
							<FcOnlineSupport className="text-[30px]" />
						</IconButton> */}
						<UserHeader />
					</div>
				</div>
			</header>
		</>
	)
}

export default AppHeader
