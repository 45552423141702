import { getSystemSettingSession } from '@Core/Helpers/Session'
import { useCoreAppContext } from '@Core/Provider/CoreAppProvider'
import { Box, InputLabel } from '@mui/material'
import clsx from 'clsx'
import { get, map } from 'lodash'
import Image from 'mui-image'
import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

const CoreSelectAvatar = ({ control, name, type = 'workspace', required, label, className, imgClassName }) => {
	const { t } = useTranslation()
	const { getDefaultImages } = useCoreAppContext()
	const {
		field: { onChange, value }
	} = useController({
		control,
		name
	})

	const renderImgs = () => {
		const data = getDefaultImages(type)
		const el = map(data, img => {
			return (
				<Box
					// role="button"
					component="p"
					className={clsx(
						'flex flex-col items-center justify-center rounded overflow-hidden w-68 cursor-pointer ',
						{
							'border-2 border-primary': img === value
						},
						imgClassName
					)}
					onClick={() => onChange(img)}
				>
					<Image src={img} />
				</Box>
			)
		})
		return <div className="flex gap-2">{el}</div>
	}

	return (
		<div className={clsx('flex flex-col gap-2', className)}>
			<InputLabel
				className={clsx('text-[1rem] md:text-12 font-medium text-label')}
				required={required}
				shrink
				htmlFor={name}
			>
				{label || t('Chọn ảnh đại diện')}
			</InputLabel>
			{renderImgs()}
		</div>
	)
}

// CoreSelectAvatar.defaultProps = {}

// CoreSelectAvatar.propTypes = {}

export default React.memo(CoreSelectAvatar)
