import { ROUTER_ORDER } from '@App/Quiz/Modules/Order/configs/const'
import { ROUTER_WORKSPACE_PLAN } from '@App/Studio/Modules/Workspace/Plan/configs/const'
import { PLAN_FEATURE } from '@Core/Components/const'
import { Alert, Button } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'

const AlertUpgradePlan = ({
	feature,
	customMsg = 'Vui lòng nâng cấp gói dịch vụ để trải nghiệm tính năng tuyệt vời này',
	className = '',
	showButton,
	onClick = () => {},
	variant
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	// if (!msg) return null
	const msg = useMemo(() => {
		if (customMsg) {
			return customMsg
		}
		if (feature === PLAN_FEATURE.exam) {
			return t('Hết lượt tạo đề thi , vui lòng nâng cấp gói dịch vụ để trải nghiệm tính năng tuyệt vời này')
		}
		if (feature === PLAN_FEATURE.question) {
			return t('Hết lượt tạo câu hỏi , vui lòng nâng cấp gói dịch vụ để trải nghiệm tính năng tuyệt vời này')
		}
	}, [t, feature, customMsg])
	return (
		<Alert
			severity="warning"
			// variant="filled"
			variant={variant}
			className={className}
			action={
				showButton && (
					<Button
						size="small"
						className="whitespace-nowrap"
						onClick={() => {
							onClick()
							navigate(`/quiz/${ROUTER_ORDER.pricing}`)
						}}
					>
						{t('Nâng cấp ngay')}
					</Button>
				)
			}
		>
			{msg}
		</Alert>
	)
}

// AlertUpgradePlan.defaultProps = {}

// AlertUpgradePlan.propTypes = {}

export default React.memo(AlertUpgradePlan)
