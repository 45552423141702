import mktService from '@App/Quiz/services/mktService'
import { useRequest } from 'ahooks'
import React from 'react'
// import PropTypes from 'prop-types'

const CoreFlashSaleContext = React.createContext({
	flashsale: {},
	isHasFlashsale: () => {}
})

export const useCoreFlashSaleContext = () => React.useContext(CoreFlashSaleContext)

const CoreFlashSaleProvider = props => {
	const { data: flashsale } = useRequest(mktService.getFlashSale)
	// console.log('============= flashsale', flashsale)

	const isHasFlashsale = () => {
		return Boolean(flashsale?.id)
	}

	const context = {
		flashsale,
		isHasFlashsale
	}
	return <CoreFlashSaleContext.Provider value={context}>{props.children}</CoreFlashSaleContext.Provider>
}

// CoreFlashSaleProvider.defaultProps = {}

// CoreFlashSaleProvider.propTypes = {}

export default React.memo(CoreFlashSaleProvider)
