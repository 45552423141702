import BaseService from '@Core/axios/BaseService'
import { hasScopeChannel } from '@Core/axios/middleware'
import { CRM_BE_URL } from 'env'

class WalletService extends BaseService {
	// BASE_URL = 'https://studio.cqfsliyqyzv0gv.flashvps.xyz'
	// BASE_URL = 'https://studio-api-01.eduquiz.io.vn'
	BASE_URL = CRM_BE_URL

	constructor(params) {
		super(params)
		this.applyMiddleware('scopeChannel', hasScopeChannel)
		this.setRequest()
	}

	init = channel => {
		const endpoint = '/api/v1/wallet-features/init'
		return this.request.get(endpoint, {
			headers: {
				channel
			}
		})
	}

	getWallets = () => {
		const endpoint = '/api/v1/wallet-features'
		return this.request.get(endpoint)
	}

	getWalletTransactions = params => {
		const { slug } = params
		const endpoint = `/api/v1/wallet-features/${slug}/transactions`
		return this.request.get(endpoint, { params })
	}

	getProductFeatures = () => {
		const endpoint = '/api/v1/product-features'
		return this.request.get(endpoint)
	}

	getBalance = slug => {
		const endpoint = `/api/v1/wallet-features/${slug}/balance`
		return this.request.get(endpoint)
	}
}

const walletFeatureService = new WalletService()
export default walletFeatureService
